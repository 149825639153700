import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams, useLocation, useHistory} from 'react-router-dom';

import {Member} from "../index";
import MemberPlans from "./MemberPlans";
import MemberRsvp from './MemberRsvp';
import Profile from "./Profile";
import TabNavigator from "../../../components/TabNavigator";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import MemberCredits from "./MemberCredits";
import MemberSalesHistory from "./MemberSalesHistory";
import baseApi from "../../../apis/baseApi";
import MemberRegistrationFees from "./MemberRegistrationFees";


const MemberDetails = () => {
    const history = useHistory()
    const location = useLocation() as { state: { member: Member } }
    let {memberId} = useParams<{ memberId: string }>()

    const [member, setMember] = useState<Member | null>(null)

    useEffect(() => {
        if (location.state) {
            setMember(location.state.member)
        } else {
            baseApi.get(`/members/${memberId}/`).then(resp => {
                setMember(resp.data)
            })
        }
    }, [location, memberId])

    useEffect(() => {
        if (member) {
            console.log("IF MEMBER")
            history.replace({...history.location, state: {member: member}})
        }
    }, [history, member]);


    if (!member) return <div>Cargando...</div>
    return (
        <div className="h-100">
            <div className="d-flex flex-row align-items-center">
                <Button onClick={history.goBack} size="sm" variant="outline-primary">
                    <i><FontAwesomeIcon icon={faChevronLeft} size="sm"/></i>Atrás
                </Button>
                <h4 className="m-0 ml-2">Usuario</h4>
            </div>
            <Row className="h-100 mt-4">
                <Col className="pl-0 pr-1" lg={3}>
                    <div className="rounded shadow-sm text-center p-4" style={{background: "white", height: "85%"}}>
                        {member ? <Profile member={member} setMember={setMember}/> : <React.Fragment/>}
                    </div>
                </Col>
                <Col lg={9}>
                    <div className="rounded shadow-sm px-2 pt-2" style={{background: "white", height: "85%"}}>
                        <TabNavigator tabLabels={["Paquetes y Membresías", "Ventas", "Reservaciones", "Créditos", "Inscripciones"]} panels={
                            [
                                <MemberPlans member={member!} setMember={setMember}/>,
                                <MemberSalesHistory member={member} />,
                                <MemberRsvp member={member}/>,
                                <MemberCredits member={member!} setMember={setMember}/>,
                                <MemberRegistrationFees member={member!} />
                            ]
                        }/>

                    </div>
                </Col>
            </Row>

        </div>
    );
};

export default MemberDetails;
