import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {startOfWeek, endOfWeek, formatISO, addDays, format, parseISO, subWeeks, addWeeks} from "date-fns";
import {es} from "date-fns/locale";

import {StudioClass} from "../../actions/studioClasses";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import {Room} from "../Toolbox/Rooms";
import ScheduleCard from "./ScheduleCard";
import SchedulesHeader from "./SchedulesHeader";
import EditCreateScheduleModal from "./EditCreateScheduleModal";
import baseApi from "../../apis/baseApi";
import DeleteModal from "../../components/modals/DeleteModal";

export interface TimeSlot {
    id: string,
    start: Date,
    start_time: string,
    schedule: string,
    available_spaces: number
    studio_class: StudioClass
    minutes_duration: number
    people_limit: number
    coaches: MinCoach[],
    room: Room | null
    occupied_seats: string[],
    activate_days_before: number | null
    close_hours_before: number | null
    publish_date?: string
    gympass_id: number | null
    rsvp_count?: number
    archived: boolean
}

export interface MinCoach {
    id: string
    full_name: string
    img: string
}

const Schedules = () => {
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [weekOf, setWeekOf] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>();
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [disableForm, setDisableForm] = useState(false)

    useEffect(() => {
        const start = formatISO(addDays(startOfWeek(weekOf), 1)).replace("+", "%2B");
        const end = formatISO(addDays(endOfWeek(weekOf), 1)).replace("+", "%2B");
        baseApi.get(`/schedules/slots/staff/?start=${start}&end=${end}&branch=${currentBranch.id}`)
            .then((resp) => {
                // @ts-ignore
                const data: TimeSlot[] = resp.data.map((t) => ({
                    ...t,
                    start: parseISO(t.start),
                } as TimeSlot))
                const sortedData = data.sort((a, b) => (a.start.getTime() - b.start.getTime()))
                setTimeSlots(sortedData)
            })

            .catch(() => alert("Error obteniendo horarios"));
    }, [currentBranch, weekOf]);

    const renderDayTimeSlots = (day: Date) => {
        let filteredTimeSlots: TimeSlot[] = []
        timeSlots.forEach((ts) => ts.start.getDay() === day.getDay() ? filteredTimeSlots.push(ts) : null)
        return filteredTimeSlots.map((ts) => <ScheduleCard
                key={ts.id}
                onDetails={() => {
                    setDisableForm(true)
                    setSelectedTimeSlot(ts)
                    setShowCreateModal(true)
                }
                }
                onEdit={() => {
                    setSelectedTimeSlot(ts)
                    setShowCreateModal(true)
                }} timeSlot={ts}
                onDelete={() => {
                    setSelectedTimeSlot(ts)
                    setShowDeleteModal(true)
                }}
            />
        )
    }

    const renderHeader = () => {
        const weekStart = addDays(startOfWeek(weekOf), 1);
        let days: Date[] = []
        let i = 0
        for (i = 0; i < 7; i++) {
            const day = addDays(weekStart, i)
            days.push(day)
        }
        return <Row className="d-flex flex-row text-center">
            {days.map((d) =>
                (
                    <Col key={d.toString()} className="px-2">
                        <div>
                            <div
                                className="card card-custom rounded-circle mx-auto shadow-sm h-35px h-sm-50px w-35px w-sm-50px">
                                <h6 className="font-weight-boldest text-center my-auto">
                                    {format(d, " d", {locale: es})}
                                </h6>
                            </div>
                            <p className="text-uppercase text-muted font-weight-bolder text-center pt-2">{format(d, "EEEE", {locale: es})}</p>
                        </div>
                        {renderDayTimeSlots(d)}
                    </Col>
                )
            )}
        </Row>
    }

    const onArrowBack = () => setWeekOf(subWeeks(weekOf, 1))
    const onArrowForward = () => setWeekOf(addWeeks(weekOf, 1))

    return (
        <div className="on-schedules">
            <DeleteModal url={`/schedules/slots/${selectedTimeSlot?.id}/`} onSuccess={() => {
                setTimeSlots(timeSlots.filter(ts => ts.id !== selectedTimeSlot?.id))
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
                setSelectedTimeSlot(undefined)
            }}>
                <p>¿Seguro que quieres eliminar este bloque?</p>
                <p><strong>{selectedTimeSlot?.studio_class.name}</strong></p>
                <p>{format(selectedTimeSlot?.start || 0, "dd-MMM-yyyy HH:mm")}</p>
            </DeleteModal>
            <EditCreateScheduleModal
                show={showCreateModal}
                onHide={() => {
                    setDisableForm(false)
                    setShowCreateModal(false)
                    setSelectedTimeSlot(undefined)
                }}
                timeslots={timeSlots}
                setTimeSlots={setTimeSlots}
                weekOf={weekOf}
                disabled={disableForm}
                selectedTimeSlot={selectedTimeSlot}
            />
            <SchedulesHeader currentDate={weekOf} onArrowBack={onArrowBack} onArrowForward={onArrowForward} setShowCreateModal={setShowCreateModal}/>
            <div className="pt-5">
                {renderHeader()}
                <div hidden={timeSlots.length > 0} className="text-center text-muted font-weight-bolder font-size-h2 mt-15">0 horarios registrados en esta fecha</div>
            </div>
        </div>
    );
};

export default Schedules;
