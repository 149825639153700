import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MemberPlan} from "./MemberPlans";
import {formatDate, formatDateTime} from "../../../utils/dateFormatters";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import MemberRsvp from "./MemberRsvp";
import {Member} from "../index";
import {Sale} from "../../Reports/Sales";
import baseApi from "../../../apis/baseApi";
import MemberCredits from "./MemberCredits";
import MembershipUpdateHistory from "./MembershipUpdateHistory";
import {Button} from "react-bootstrap";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";

interface Props {
    member: Member
    membership: MemberPlan
    onClose: () => void
}

const MembershipDetails = ({member, membership, onClose}: Props) => {
    const [sale, setSale] = useState<Sale>();

    useEffect(() => {
        if (membership.sale) {
            baseApi.get(`/sales/${membership.sale}/`).then(resp => {
                setSale(resp.data)
            })
        }
    }, [membership]);

    return (
        <div className="p-5 mx-10">
            <Button onClick={onClose} className="px-2 py-1 mb-1" variant="outline-primary" size="sm">
                <FontAwesomeIcon icon={faArrowLeft}/> Atrás
            </Button>
            <h4 className="m-0 p-0">Detalles de Membresía</h4>
            <div className="row">
                <div className="col-12">
                    <div className="font-weight-bold font-size-lg">
                        {membership.plan.name}
                        {membership.subscription_id ?
                            <FontAwesomeIcon className="text-success ml-2" icon={faSyncAlt}/> :
                            <React.Fragment/>}
                    </div>
                    <div>Vigencia: {formatDate(membership.activate_on)} - {formatDate(membership.expire_on)}</div>
                    <div>Creado: {formatDateTime(membership.created_on)}</div>
                </div>
            </div>
            <hr/>
            <div>
                <h5>Venta</h5>
                {!sale ? <div>Cargando</div> : <React.Fragment/>}
                <div hidden={!sale}>
                    <div>Precio: {sale?.plan_items.find(i => i.plan === membership.plan.name)?.price}</div>
                    <div>Método de pago: {sale?.payment_method}</div>
                    <div>Creado por: {sale?.transaction_by}</div>
                </div>
            </div>
            <hr/>
            <div>
                <h5>Actualizaciones a la vigencia</h5>
                <MembershipUpdateHistory membership={membership}/>
            </div>
            <hr/>
            <div className="row mt-2">
                <div className="col-6">
                    <h5>Reservaciones</h5>
                    <MemberRsvp member={member} membershipId={membership.id}/>
                </div>
                <div className="col-6">
                    <h5>Créditos</h5>
                    <MemberCredits member={member} setMember={(m) => {
                    }} membershipId={membership.id} compactView={true}/>
                </div>
            </div>
        </div>
    )
};

export default MembershipDetails;
