import React from 'react';
import {useState} from 'react';
import {Alert, Badge, Button, Form, Modal, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, FieldArray} from "formik";
import FormField from "../../../components/form/FormField";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {StudioClassCategory, StudioClassesState} from "../../../actions/studioClasses";
import baseApi from "../../../apis/baseApi";
import {BranchesState} from "../../../actions";
import FormInputGroup from "../../../components/form/FormInputGroup";
import {addPlan, PlanDateRestrictions, PlanPenalizations, StudioPlan, updatePlan} from "../../../actions/studioPlans";
import FormSelectAlt, {SelectOption} from "../../../components/form/FormSelectAlt";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import Checkbox from "../../../components/form/Checkbox";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStripe} from "@fortawesome/free-brands-svg-icons";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {format, parse} from "date-fns";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FormikSelect} from "../../../components/form/FormikSelect";
import {Studio} from "../../../actions/studio";

type CreatePlanModalProps = {
    show: boolean,
    onHide: Function,
    noLimitCredits?: boolean
    plan?: StudioPlan
    onUpdate?: (plan: StudioPlan) => void
}

const CreateEditPlanModal = ({show, onHide, plan, onUpdate, noLimitCredits = false}: CreatePlanModalProps) => {
    const dispatch = useDispatch()
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const classesState = useSelector<StoreState, StudioClassesState>(state => state.studioClasses)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");
    const classCategories = useSelector<StoreState, StudioClassCategory[]>(state => state.studio.studio_class_categories)
    const classCategoriesBetaStudios = [
        "a9d64f87-5d5a-4f59-98a2-56d70e63d27e",
        "31ab5015-8064-4a03-b67c-5a84ab3b12a0",
        "9a3c10bd-cf10-48f9-a71d-3ee284fdc67c",
    ]

    // @ts-ignore
    const CustomCheckbox = ({field, form: {touched, errors}, ...props}) => (
        <label className="col-form-label px-lg-0 checkbox">
            <input type="checkbox" {...field} {...props} />
            <span/>
            <div className="ml-2">{props.label}</div>
            {touched[field.name] &&
                errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </label>
    );

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    // TODO: validate max 1 year interval for subscriptions
    const validationSchema = yup.object().shape({
        name: yup.string().required().min(3),
        // days_duration: yup.number().required().min(1),
        interval_count: yup.number().required().min(1),
        price: yup.number().required().min(0),
        max_rsvp_cancellations: yup.number().nullable().min(0),
        max_rsvp_without_payment: yup.number().required().min(0),
        // allowed_classes: yup.array().required().min(1, "Selecciona al menos 1 opción"),
        penalizations: yup.object().nullable().shape({
            no_show_fee: yup.number().required(),
            no_show_min_count: yup.number().required().min(1)
        })
    })
    const customStyles = {
        // @ts-ignore
        control: (provided, state) => ({
            ...provided,
            background: '#F3F6F9',
            borderColor: '#E4E6EF',
            minHeight: '33px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
        }),
// @ts-ignore
        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),
// @ts-ignore
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        // @ts-ignore
        indicatorSeparator: state => ({
            display: 'none',
        }),
        // @ts-ignore
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            width: '24px',
            paddingTop: 0,
            paddingBottom: 0,
            // viewBox: '0 0 5 5',
        }),
        // @ts-ignore
        dropdownIndicator: (provided, state) => ({
            // width: '5',
            // height: '5',
            color: 'grey',
            // viewBox: '0 0 5 5',
            paddingTop: 0,
            paddingBottom: 0,
        })
    };
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        name: plan?.name,
                        price: plan?.price,
                        credits: plan?.credits,
                        no_limit_credits: noLimitCredits,
                        days_duration: plan?.days_duration,
                        interval: plan?.interval ?? 'day',
                        interval_count: plan?.interval_count,
                        day_reservations_limit: plan?.day_reservations_limit,
                        week_reservations_limit: plan?.week_reservations_limit,
                        allow_all_classes: !plan ? true : plan.allow_all_classes,
                        allow_all_branches: !plan ? true : plan.allow_all_branches,
                        allow_friend_rsvp: !plan ? false : plan.allow_friend_rsvp,
                        allowed_classes: !plan ? [] : plan.allowed_classes.map(c => c.id),
                        // allowed_classes: !plan ? [{
                        //     value: "ALL",
                        //     label: "Todas"
                        // }] : plan.allow_all_classes ? [{
                        //     value: "ALL",
                        //     label: "Todas"
                        // }] : plan.allowed_classes.map(c => ({value: c.id, label: c.name})),
                        allowed_class_categories: !plan ? [] : classCategories.filter(c => plan.allowed_class_categories.includes(c.id)).map(c => ({
                            value: c.id,
                            label: c.name
                        })),
                        public_visible: !plan ? true : plan.archived ? false : plan.public_visible,
                        max_purchases_per_member: plan?.max_purchases_per_member,
                        is_subscription: plan?.is_subscription ?? false,
                        bills_on_1st_of_month: plan?.bills_on_1st_of_month ?? false,
                        date_restrictions: plan?.date_restrictions ?? null,
                        time_restrictions: plan?.time_restrictions ?? [],
                        branches: !plan || plan?.allow_all_branches ? [{
                            value: "ALL",
                            label: "Todas"
                        }] : branchesState.selectOptions.filter(option => plan.branches.includes(option.value)),
                        penalizations: !plan ? null : plan.penalizations,
                        requires_registration_fee: plan?.requires_registration_fee ?? false,
                        max_rsvp_cancellations: plan?.max_rsvp_cancellations,
                        max_rsvp_without_payment: plan?.max_rsvp_without_payment ?? 1,
                        updates_expiration_on_first_rsvp: plan?.updates_expiration_on_first_rsvp ?? false
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        let daysDuration = values.interval_count!
                        if (values.interval === "month") {
                            daysDuration *= 30
                        } else if (values.interval === "week") {
                            daysDuration *= 7
                        } else if (values.interval === "year") {
                            daysDuration *= 365
                        }

                        // let allowedClasses = values.allowed_classes.map(c => c.value)
                        // values.allow_all_classes = allowedClasses.includes("ALL")
                        // if (values.allow_all_classes) {
                        //     allowedClasses = []
                        // } else {
                        //     allowedClasses = allowedClasses.filter(c => c !== "ALL")
                        // }

                        const allowedClassCategories = values.allowed_class_categories.map(c => c.value)
                        if (allowedClassCategories.length > 0) {
                            // allowedClasses = []
                            values.allow_all_classes = false
                        }
                        if (plan) {
                            baseApi.put(`/plans/${plan.id}/`, {
                                ...values,
                                days_duration: daysDuration,
                                week_reservations_limit: values.week_reservations_limit || null,
                                day_reservations_limit: values.day_reservations_limit || null,
                                max_purchases_per_member: values.max_purchases_per_member || null,
                                max_rsvp_cancellations: values.max_rsvp_cancellations || null,
                                branches: values.branches.flatMap((option: SelectOption) => option.value !== "ALL" ? option.value : []),
                                allowed_class_categories: allowedClassCategories,
                                // allowed_classes: allowedClasses,
                            }).then((resp) => {
                                if (plan.archived) {
                                    if (onUpdate) {
                                        onUpdate(resp.data)
                                    }
                                } else {
                                    dispatch(updatePlan({...resp.data, promotions: plan.promotions}))
                                }
                                setAlertMsg("Plan actualizado correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        } else {
                            baseApi.post("/plans/", {
                                ...values,
                                days_duration: daysDuration,
                                week_reservations_limit: values.week_reservations_limit || null,
                                day_reservations_limit: values.day_reservations_limit || null,
                                max_purchases_per_member: values.max_purchases_per_member || null,
                                branches: values.branches.flatMap((option: SelectOption) => option.value !== "ALL" ? option.value : []),
                                allowed_class_categories: allowedClassCategories,
                                // allowed_classes: allowedClasses,
                            }).then((resp) => {
                                dispatch(addPlan({...resp.data, promotions: []}))
                                setAlertMsg("Plan creado correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }
                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                {plan ? "Editar " : "Crear "} {noLimitCredits ? "Membresía" : "paquete de créditos"}
                                <Badge hidden={!plan?.archived} variant='warning'>Archivado</Badge>
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FieldError name="name"/>
                                <FormField disabled={plan?.archived || plan?.is_manual_credits} label="Nombre" name="name"/>
                                <hr/>
                                <FieldError name="credits"/>
                                {noLimitCredits ?
                                    <React.Fragment/>
                                    : <FormField disabled={plan?.archived || plan?.is_manual_credits} label="Créditos" name="credits"/>
                                }
                                <FieldError name="interval_count"/>
                                <div className="form-group row">
                                    <label className="col-lg-2 offset-lg-1 col-form-label">Duración</label>
                                    <div className="col-lg-4">
                                        <div className="input-group input-group-sm">
                                            <Field name="interval_count" type="number" className="form-control"
                                                   placeholder="30" disabled={values.is_subscription ||plan?.archived || plan?.is_manual_credits}/>
                                            <div className="input-group-append ">
                                                <div className="" style={{width: "100px"}}>
                                                    <Field

                                                        styles={customStyles}
                                                        name="interval"
                                                        disabled={values.is_subscription || plan?.archived}
                                                        isDisabled={values.is_subscription || plan?.archived}
                                                        component={FormikSelect}
                                                        options={[
                                                            {label: "días", value: "day"},
                                                            {label: "semanas", value: "week"},
                                                            {label: "meses", value: "month"},
                                                            {label: "años", value: "year"}
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FieldError name="price"/>
                                <FormInputGroup name="price" label="Precio" groupText="$" prepend={true}
                                                disabled={plan?.is_subscription || plan?.archived || plan?.is_manual_credits}/>
                                <div hidden={plan?.is_manual_credits}>
                                    <div  className="offset-lg-1 d-inline-flex">
                                        <Field disabled={plan} component={CustomCheckbox} type="checkbox"
                                               name="is_subscription"
                                               label="Suscripción (cargo domiciliado)"/>
                                        <FontAwesomeIcon color="purple" size={"2x"} icon={faStripe}/>
                                    </div>
                                </div>
                                <div hidden={!values.is_subscription} className="offset-lg-1">
                                    <div>Día de cobro</div>
                                    <ToggleButtonGroup defaultValue={values.bills_on_1st_of_month ? 1 : 0} size="sm"
                                                       name="yessir" type="radio" onChange={(v: any) => {
                                        setFieldValue("bills_on_1st_of_month", v === 1)
                                    }}>
                                        <ToggleButton disabled={plan !== undefined && values.bills_on_1st_of_month}
                                                      onChange={() => {
                                                      }} value={0}>Mismo día del pago</ToggleButton>
                                        <ToggleButton disabled={plan !== undefined && !values.bills_on_1st_of_month}
                                                      onChange={() => {
                                                      }} value={1}>1ro de cada mes</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                <hr/>
                                <h5 className="offset-lg-1 pb-4">Condiciones</h5>
                                <div hidden={plan?.archived || plan?.is_manual_credits}>
                                    <div className="offset-lg-1 d-inline-flex pb-4 align-items-center">
                                        <Field component={Checkbox} name="public_visible"
                                               label="Vender en app y web"/>
                                    </div>
                                </div>
                                <div className="offset-lg-1 pb-4 d-inline-flex align-items-center">
                                    <Field component={Checkbox} name="allow_friend_rsvp"
                                           label="Permitir reservar para un amigo"/> <span
                                    className="text-muted font-size-xs ml-1">(Usuarios podrán reservar mas de un lugar)</span>
                                </div>
                                <div hidden={plan?.is_manual_credits}>
                                    <div className="offset-lg-1 d-inline-flex pb-4 align-items-center">
                                        <Field  component={Checkbox} name="requires_registration_fee"
                                               label="Cliente requiere comprar inscripción"/>
                                    </div>
                                </div>
                                <div hidden={values.is_subscription || plan?.is_manual_credits}>
                                    <div className="offset-lg-1 d-inline-flex pb-4 align-items-center">
                                        <Field component={Checkbox} name="updates_expiration_on_first_rsvp"
                                               label="Activar vigencia a partir de primer fecha de reservación"/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-lg-4 offset-lg-1 col-form-label ">
                                        - Limite de Reservaciones
                                    </label>
                                    <div className="col-lg-2 ml-n20">
                                        <div className="input-group ">
                                            <Field name="day_reservations_limit" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">/dia</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="input-group ">
                                            <Field name="week_reservations_limit" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">/semana</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div hidden={plan?.is_manual_credits} className="form-group row">
                                    <label className="col-lg-5 offset-lg-1 col-form-label ">
                                        - Limite de reservas con deuda
                                    </label>
                                    <div className="col-lg-2 ml-n20">
                                        <FieldError name="max_rsvp_without_payment"/>
                                        <Field name="max_rsvp_without_payment" className="form-control"
                                               placeholder=""/>
                                    </div>
                                    <div className="col-lg-5"/>
                                </div>
                                <div hidden={plan?.is_manual_credits} className="form-group row">
                                    <label className="col-lg-4 offset-lg-1 col-form-label ">
                                        - Limite de compras
                                    </label>
                                    <div className="col-lg-3 ml-n20">
                                        <FieldError name="max_purchases_per_member"/>
                                        <div className="input-group ">
                                            <Field name="max_purchases_per_member" className="form-control"
                                                   placeholder=""/>
                                            <div className="input-group-append font-size-xs"><span
                                                className="input-group-text text-muted font-size-sm">/cliente</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5"/>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-5 offset-lg-1 col-form-label ">
                                        - # máximo de cancelaciones
                                    </label>
                                    <div className="col-lg-2 ml-n20">
                                        <FieldError name="max_rsvp_cancellations"/>
                                        <Field name="max_rsvp_cancellations" className="form-control"
                                               placeholder=""/>
                                    </div>
                                    <div className="col-lg-5"/>
                                </div>

                                <FormSelectAlt
                                    isDisabled={plan?.archived || plan?.is_manual_credits}
                                    label="Sucursales" placeholder="seleccionar"
                                    value={values.branches}
                                    options={[{
                                        value: "ALL",
                                        label: "Todas"
                                    }, ...branchesState.selectOptions]}
                                    onChange={(options: SelectOption[]) => {
                                        const todas = options.find(op => op.value === "ALL")
                                        if (todas) {
                                            setFieldValue("allow_all_branches", true)
                                        } else {
                                            setFieldValue("allow_all_branches", false)
                                        }
                                        setFieldValue("branches", options)
                                    }}
                                    offset="1"
                                    isMulti={true}/>
                                <div className=" offset-lg-1 d-inline-flex align-content-center justify-content-start">
                                    <div className="p-0 m-0 mr-2">- Limitar por días de la semana</div>
                                    <label className="col-form-label py-1 checkbox">
                                        <input className=" py-1" type="checkbox"
                                               defaultChecked={values.date_restrictions !== null} onChange={v => {
                                            if (v.target.checked) {
                                                let restrictions: PlanDateRestrictions = plan?.date_restrictions ?? {
                                                    id: "",
                                                    active: true,
                                                    monday: false,
                                                    tuesday: false,
                                                    wednesday: false,
                                                    thursday: false,
                                                    friday: false,
                                                    saturday: false,
                                                    sunday: false,
                                                }
                                                setFieldValue("date_restrictions", restrictions)
                                            } else {
                                                setFieldValue("date_restrictions", null)
                                            }
                                        }}/>
                                        <span/>
                                    </label>
                                </div>
                                <div hidden={!values.date_restrictions}>
                                    <div className="offset-lg-1 d-flex flex-row align-items-center pl-5">
                                        <div className="d-flex align-items-center flex-column">
                                            <Field name="date_restrictions.monday" component={Checkbox}/>
                                            <label>Lu</label>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <Field name="date_restrictions.tuesday" component={Checkbox}/>
                                            <label>Ma</label>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <Field name="date_restrictions.wednesday" component={Checkbox}/>
                                            <label>Mi</label>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <Field name="date_restrictions.thursday" component={Checkbox}/>
                                            <label>Ju</label>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <Field name="date_restrictions.friday" component={Checkbox}/>
                                            <label>Vi</label>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <Field name="date_restrictions.saturday" component={Checkbox}/>
                                            <label>Sa</label>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <Field name="date_restrictions.sunday" component={Checkbox}/>
                                            <label>Do</label>
                                        </div>
                                    </div>

                                </div>
                                <div/>
                                {/*RANGOS DE HORAS*/}
                                <div className=" offset-lg-1 d-inline-flex align-content-center justify-content-start">
                                    <div className="p-0 m-0 mr-2">- Limitar por horas</div>
                                    <label className="col-form-label py-1 checkbox">
                                        <input className=" py-1" type="checkbox"
                                               defaultChecked={values.time_restrictions.length > 0} onChange={v => {
                                            if (v.target.checked) {
                                                if (values.time_restrictions.length === 0) {
                                                    setFieldValue("time_restrictions", [{
                                                        start_time: "00:00:00",
                                                        end_time: "23:59:59"
                                                    }])
                                                }
                                            } else {
                                                setFieldValue("time_restrictions", [])
                                            }
                                        }}/>
                                        <span/>
                                    </label>
                                </div>
                                <Form.Group hidden={values.time_restrictions.length === 0}>
                                    <div className="offset-lg-1 pl-5">
                                        <FieldArray
                                            name="time_restrictions"
                                            render={arrayHelpers => <div>
                                                {values.time_restrictions.map((timeRestriction, index) => (
                                                    <div key={index}>
                                                        <div
                                                            className="d-flex flex-row align-items-center pb-1">
                                                            <div className="customDatePickerWidth"
                                                                 style={{width: "80px"}}>
                                                                <ReactDatePicker
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    locale={es}
                                                                    selected={parse(values.time_restrictions[index].start_time!, "HH:mm:ss", new Date())}
                                                                    onChange={date => {
                                                                        setFieldValue(`time_restrictions[${index}].start_time`, format(date as Date, "HH:mm:ss"))
                                                                    }}
                                                                    timeFormat="h:mm aa"
                                                                    dateFormat="h:mm aa"
                                                                    timeIntervals={15}
                                                                    timeCaption={"Hora"}
                                                                    customInput={<input
                                                                        className="form-control form-control-sm"/>}
                                                                />
                                                            </div>
                                                            <div className="px-2">a</div>
                                                            <div className="customDatePickerWidth"
                                                                 style={{width: "80px"}}>
                                                                <ReactDatePicker
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    minTime={parse(values.time_restrictions[index].start_time!, "HH:mm:ss", new Date())}
                                                                    maxTime={new Date(2023, 1, 1, 23, 59)}
                                                                    locale={es}
                                                                    selected={parse(values.time_restrictions[index].end_time, "HH:mm:ss", new Date())}
                                                                    onChange={date => {
                                                                        setFieldValue(`time_restrictions[${index}].end_time`, format(date as Date, "HH:mm:ss"))
                                                                    }}
                                                                    timeFormat="h:mm aa"
                                                                    dateFormat="h:mm aa"
                                                                    timeIntervals={15}
                                                                    timeCaption={"Hora"}
                                                                    customInput={<input
                                                                        className="form-control form-control-sm"/>}
                                                                />
                                                            </div>
                                                            <div className='pl-2 align-self-center'>
                                                                <Button
                                                                    disabled={values.time_restrictions?.length === 1}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                    className="btn btn-icon btn-light-danger btn-xs btn-hover-danger mr-1">
                                                                    <FontAwesomeIcon icon={faTimes} size="sm"/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <Button className="align-self-center p-0 m-0 pt-3" variant="link"
                                                        size="sm"
                                                        onClick={() => {
                                                            setFieldValue("time_restrictions", [...values.time_restrictions, {
                                                                start_time: "00:00:00",
                                                                end_time: "23:59:59"
                                                            }])
                                                        }}>+ Agregar hora
                                                </Button>
                                            </div>
                                            }
                                        />
                                    </div>
                                </Form.Group>
                                {/*FIN RANGO HORAS*/}
                                <hr hidden={plan?.is_manual_credits}/>
                                <div hidden={!classCategoriesBetaStudios.includes(studio.id) || plan?.is_manual_credits}>
                                    <FormSelectAlt
                                        isMulti
                                        label="Categorias de clases permitidas"
                                        placeholder="Categorias"
                                        comment={"Opcional"}
                                        value={values.allowed_class_categories}
                                        options={classCategories.map(c => {
                                            return {label: c.name, value: c.id}
                                        })}
                                        classNames="mb-0 mt-3"
                                        offset="1"
                                        onChange={(p: SelectOption[] | undefined) => {
                                            setFieldValue("allowed_class_categories", p)
                                            // setFieldValue("allowed_class_categories", p)
                                        }}/>
                                </div>
                                <div hidden={values.allowed_class_categories.length > 0 || plan?.is_manual_credits}>
                                    <div className="offset-lg-1">
                                        <label className="col-form-label">
                                            - Clases
                                        </label>
                                        <Field component={CustomCheckbox} type="checkbox" name="allow_all_classes"
                                               label="Todas"/>
                                        {values.allow_all_classes ?
                                            <React.Fragment/> :
                                            classesState.classes.map((c) => (
                                                <Field key={c.id} component={CustomCheckbox} type="checkbox" value={c.id}
                                                       name="allowed_classes" label={c.name}/>
                                            ))
                                        }
                                    </div>
                                        {/*<FormSelectAlt*/}
                                    {/*    isMulti*/}
                                    {/*    label="Clases"*/}
                                    {/*    placeholder="Seleccionar clases"*/}
                                    {/*    // comment={"Opcional"}*/}
                                    {/*    value={values.allowed_classes}*/}
                                    {/*    options={[{label: "Todas", value: "ALL"}, ...classesState.classes.map(c => {*/}
                                    {/*        return {label: c.name, value: c.id}*/}
                                    {/*    })]}*/}
                                    {/*    classNames="mb-0 mt-3"*/}
                                    {/*    offset="1"*/}
                                    {/*    onChange={(p: SelectOption[] | undefined) => {*/}
                                    {/*        setFieldValue("allowed_classes", p)*/}
                                    {/*    }}/>*/}
                                </div>
                                <FieldError name="allowed_classes"/>
                                {/*This field is another way of modifying allowed_classes value*/}
                                {/*{values.allow_all_classes ? <div>*/}
                                {/*    <FormSelectAlt*/}
                                {/*        isMulti label="Excluir clases" placeholder="Clases" value={undefined}*/}
                                {/*        options={classesState.classes.filter(c => !values.allowed_classes.includes(c.id)).map(c => {*/}
                                {/*            return {label: c.name, value: c.id}*/}
                                {/*        })}*/}
                                {/*        onChange={(p: SelectOption[] | undefined) => {*/}
                                {/*            setFieldValue("allowed_classes", p?.map(option => option.value))*/}
                                {/*        }}/>*/}
                                {/*</div> : <React.Fragment/>}*/}
                                <hr/>
                                {/* PENALIZATIONS */}
                                <div hidden={plan?.is_manual_credits} className="offset-lg-1">
                                    <div className="d-inline-flex align-content-center justify-content-start">
                                        <label className="col-form-label py-1 checkbox">
                                            <input className=" py-1" type="checkbox"
                                                   defaultChecked={values.penalizations !== null} onChange={v => {
                                                if (v.target.checked) {
                                                    let penalizations: PlanPenalizations = plan?.penalizations ?? {
                                                        id: "",
                                                        no_show_fee: null,
                                                        no_show_min_count: 1,
                                                        block_rsvp_on_pending_fee: false,
                                                    }
                                                    setFieldValue("penalizations", penalizations)
                                                } else {
                                                    setFieldValue("penalizations", null)
                                                }
                                            }}/>
                                            <span/>
                                        </label>
                                        <h5 className="p-0 m-0 ml-1">Penalizaciones</h5>
                                    </div>
                                    <div className="offset-lg-1" hidden={!values.penalizations}>
                                        {/*Multa por no asistir*/}
                                        <div className="form-group row">
                                            <label className={"col-form-label"}>
                                                - Multa por no asistir
                                            </label>
                                            <div className="col-lg-4">
                                                <div className="input-group input-group-sm">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <Field type="text" name="penalizations.no_show_fee"
                                                           className="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <FieldError name="penalizations.no_show_fee"/>
                                        <div className="form-group row">
                                            <label className="col-lg-4 col-form-label mr-2 ">
                                                - Aplicar al acumular
                                            </label>
                                            <div className="col-lg-3 ml-n20">
                                                <FieldError name="max_purchases_per_member"/>
                                                <div className="input-group ">
                                                    <Field name="penalizations.no_show_min_count" min={1}
                                                           className="form-control"
                                                           placeholder=""/>
                                                    <div className="input-group-append font-size-xs"><span
                                                        className="input-group-text text-muted font-size-sm">faltas</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5"/>
                                            <FieldError name="penalizations.no_show_min_count"/>
                                        </div>
                                        <div className="d-inline-flex">
                                            - Bloquear reservaciones al tener multa pendiente
                                            <div className="ml-2">
                                                <Field name="penalizations.block_rsvp_on_pending_fee"
                                                       component={Checkbox}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditPlanModal;
