import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {startOfDay, endOfDay, formatISO, format, parseISO, parse} from "date-fns"

import {TimeSlot} from "../Schedules";
import baseApi from "../../apis/baseApi";
import TimeSlotListCard from "./TimeSlotListCard";
import ReactDatePicker from "react-datepicker";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import EmptyAlert from "../../components/misc/Empty";
import WaitListTable from "./WaitListTable";
import TabNavigator from "../../components/TabNavigator";
import ReservationsTable from "./ReservationsTable";
import {fetchReservations, TimeslotReservationsState} from "../../actions/timeslotReservations";
import CreateReservationModal from "./CreateReservationModal";
import {CSVLink} from "react-csv";
import CreateWaitListModal from "./CreateWaitListModal";
import CreateNotificationModal from "../Notifications/PushNotificationsView/CreateNotificationModal";
import CreateRecurrentRsvpModal from "./CreateRecurrentRsvpModal";
import {formatDateWithWeekday} from "../../utils/dateFormatters";
import LoadingRoller from "../../components/misc/LoadingRoller";


export interface DashboardReservationMember {
    full_name: string
    email?: string
    phone?: string
    gympass_user_id: string | null
}

interface ReservationMember {
    id: string
    external_id: string
    full_name: string
    last_name?: string
    phone: string
    shoe_size: string
    birthday?: string
    injuries?: string
}

export interface Reservation {
    id: string,
    member?: ReservationMember
    dashboard_reservation?: DashboardReservationMember
    assistance: boolean | null
    cancelled: boolean
    email: string
    seat: string | null
    cancelled_on?: string
    is_first: boolean
    has_debt: boolean
    fees_amount: number | null
    gympass_id: string | null
    created_on: string
}

const Reservations = () => {
    const dispatch = useDispatch()
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const rsvpState = useSelector<StoreState, TimeslotReservationsState>(state => state.timeSlotReservations)

    const [fetchingSlots, setFetchingSlots] = useState(false);
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([])
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null)
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAddToWaitListModal, setShowAddToWaitListModal] = useState(false);
    const [showCreateNotificationModal, setShowCreateNotificationModal] = useState(false);
    const [showCreateRecurrentRsvpModal, setShowCreateRecurrentRsvpModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedRsvp, setSelectedRsvp] = useState<Reservation>();
    const [includeArchivedSlots, setIncludeArchivedSlots] = useState(false);

    const multipleRsvpBranches = ["a913fa28-82f2-457a-b24d-6771c86763ba", "846da2e8-b1f3-465e-a13f-90879359b947", "ff6fbfdb-8cd9-4932-89e4-195840da0eb4", "bd375bb6-d8df-4321-be63-cf026fb9bbbe", "4374e711-3ab0-4119-adc1-7172f698dd9d", "b572e6a2-b8b8-4b32-bbce-ad1ada619612", "cf342756-b69a-4293-81e3-df88171320e7", "717c30cd-e633-420c-bbb2-f32e3d5c4728", "6551f81e-7ecb-49b7-a6d9-c1944ee941f1", "34c0cceb-7206-4244-b149-2e19e9fd68f2", "54f43824-b011-4004-be94-a141465bfff9", "0b78b09d-a4a4-457c-83b0-fa24e3f4b3a3", "80f66261-d0cf-437e-a53d-916984c72955"] // TODO: remove after beta test

    useEffect(() => {
        setFetchingSlots(true)
        const start = formatISO(startOfDay(selectedDate)).replace("+", "%2B")
        const end = formatISO(endOfDay(selectedDate)).replace("+", "%2B")
        let url = `/schedules/slots/staff/?start=${start}&end=${end}&branch=${currentBranch.id}`
        if (includeArchivedSlots) {
            url += "&archived=1"
        }
        baseApi.get(url).then((resp) => {
            // @ts-ignore
            const data: TimeSlot[] = resp.data.map((t) => {
                let start = parseISO(t.start)
                if (t.start_time !== null) {
                    start = parse(t.start_time, "HH:mm:ss", start)
                }
                return ({
                    ...t,
                    start: start,
                } as TimeSlot);
            })
            const sortedData = data.sort((a, b) => (a.start.getTime() - b.start.getTime()))
            setTimeSlots(sortedData)
            setFetchingSlots(false)
        }).catch(() => alert("Error obteniendo las reservaciones"))
    }, [selectedDate, currentBranch, includeArchivedSlots])

    useEffect(() => {
        if (timeSlots.length > 0) {
            if (!selectedTimeSlot || selectedDate.getDate() !== selectedTimeSlot.start.getDate()) {
                setSelectedTimeSlot(timeSlots[0])
            }
        } else {
            setSelectedTimeSlot(null)
        }
    }, [timeSlots, selectedTimeSlot])

    useEffect(() => {
        if (selectedTimeSlot !== null) {
            dispatch(fetchReservations(selectedTimeSlot))
        }
    }, [selectedTimeSlot, dispatch]);

    const renderTimeSlots = () => {
        if (fetchingSlots) {
            return <div>
                <LoadingRoller />
            </div>
        }
        if (timeSlots.length === 0) {
            return <div className="mt-5 pt-5 d-flex justify-content-center align-items-center">0 horarios registrados
                para este dia</div>
        }
        return <div style={{overflowY: "auto", overflowX: "hidden"}}>
            {timeSlots.map((ts) => {
                return <TimeSlotListCard key={ts.id} setSelectedTimeSlot={setSelectedTimeSlot} timeSlot={ts}/>
            })}
        </div>

    }

    const renderSelectedTimeSlot = () => {
        if (selectedTimeSlot === null) {
            return <EmptyAlert/>
        }
        return <div>

            <div className="d-flex justify-content-between mb-10">
                <div>
                    <div className="font-weight-bolder text-uppercase mb-1" style={{opacity: "0.3"}}>
                        {formatDateWithWeekday(selectedTimeSlot.start)}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className=" font-size-h3 font-weight-bold">{format(selectedTimeSlot.start, "h:mm aa")}
                            {/* <span style={{color: selectedTimeSlot.studio_class.color}}
                                  className="ml-2">{selectedTimeSlot.studio_class.name}</span> */}
                        </div>
                        <div className='ml-3 border-left py-2'>
                            <div style={{color: selectedTimeSlot.studio_class.color}}
                                 className="ml-2 font-size-h6 font-weight-bold">
                                {selectedTimeSlot.studio_class.name}
                            </div>
                            <div className="d-flex ">
                                {/* <div className="text-muted pr-2"><FontAwesomeIcon icon={faUser}/></div> */}
                                <div
                                    className="text-muted ml-2">{selectedTimeSlot.coaches.map(c => c.full_name).join(", ")}</div>
                            </div>
                        </div>
                    </div>
                    <span
                        hidden={!selectedTimeSlot.archived}
                        className="label label-inline label-light-danger label-pill font-weight-bolder font-size-lg">
                            Eliminado
                    </span>
                    <div style={{color: 'salmon'}} hidden={!selectedTimeSlot.gympass_id} className="">GymPass Slot
                        ID: {selectedTimeSlot.gympass_id}</div>
                    {/*<div style={{color: 'salmon'}} hidden={!selectedTimeSlot.studio_class?.gympass_config?.gympass_id}>GymPass ID Clase: {!selectedTimeSlot.studio_class?.gympass_config?.gympass_id}</div>*/}

                </div>

                <div className="d-inline-flex">
                    {/*Notification button*/}
                    <div hidden={selectedTimeSlot?.archived}>
                        <button className="btn btn-white btn-icon btn-circle btn-lg shadow ml-3"
                                onClick={() => setShowCreateNotificationModal(true)}>
                            <div>
                                <i className="fi-rr-bell text-dark-50"></i>
                            </div>
                        </button>
                        <div
                            className="text-primary text-center opacity-70 mt-1 font-size-xs font-weight-bold">enviar<br/>notificación
                        </div>
                    </div>
                    {/* RESERVATION BUTTON */}
                    <div hidden={selectedTimeSlot?.archived || selectedTimeSlot?.available_spaces === 0}
                         className="text-center ml-3">
                        <button onClick={() => setShowCreateModal(true)}
                                className="btn btn-white btn-icon btn-circle btn-lg shadow ">
                            <i className="fi-rr-user-add text-dark-50"/>
                        </button>
                        <div className="text-primary opacity-70 mt-1 font-size-xs font-weight-bold">agregar<br/>reservación
                        </div>
                    </div>
                    {/*RECURRENT RESERVATION BUTTON*/}
                    <div
                        hidden={selectedTimeSlot?.archived || !multipleRsvpBranches.includes(currentBranch.id) || selectedTimeSlot?.available_spaces === 0}
                        className="text-center ml-3">
                        <button onClick={() => setShowCreateRecurrentRsvpModal(true)}
                                className="btn btn-white btn-icon btn-circle btn-lg shadow ">
                            <i className="fi-rr-angle-double-small-right text-dark-50"/>
                        </button>
                        <div className="text-primary opacity-70 mt-1 font-size-xs font-weight-bold">reservación<br/>multiple
                        </div>
                    </div>
                    {/* WAITLIST BUTTON */}
                    <div hidden={selectedTimeSlot?.archived || selectedTimeSlot?.available_spaces > 0}
                         className="text-center ml-3">
                        <button onClick={() => setShowAddToWaitListModal(true)}
                                className="btn btn-white btn-icon btn-circle btn-lg shadow">
                            <i className="fi-rr-user-time text-dark-50"/>
                        </button>
                        <div className="text-primary opacity-70 mt-1 font-size-xs font-weight-bold">agregar<br/>waitlist
                        </div>
                    </div>
                    {/*DOWNLOAD BUTTON*/}
                    <CSVLink
                        filename={`${selectedTimeSlot?.studio_class.name} ${format(selectedTimeSlot?.start, "dd-MMM-yyyy H_mm")}`}
                        data={rsvpState.reservations.map(r => {
                            let cliente = r.member ? `${r.member.external_id} - ${r.member.full_name}` : r.dashboard_reservation ? `${r.dashboard_reservation.full_name}` : ""
                            return {
                                "CLIENTE": cliente,
                                "SPOT": r.seat,
                                "TALLA": r.member?.shoe_size,
                                "CHECK-IN": r.assistance ? "SI" : "NO",
                                "CANCELADA": r.cancelled ? "SI" : ""
                            }
                        })}>
                        <button className="btn btn-white btn-icon btn-circle btn-lg shadow ml-3">
                            <i className="fi-rr-download text-dark-50"/>
                        </button>
                    </CSVLink>
                </div>

            </div>
            <TabNavigator tabLabels={["Reservaciones", "Waitlist"]}
                          panels={[
                              <ReservationsTable onEditSpotClick={(rsvp) => {
                                  setSelectedRsvp(rsvp)
                                  setShowCreateModal(true)
                              }}/>,
                              <WaitListTable timeslot={selectedTimeSlot}/>
                          ]}/>
        </div>
    }

    return (
        <div className="h-100 on-rsvp on-check-in on-waitlist">
            <CreateReservationModal
                show={showCreateModal}
                onHide={() => {
                    setShowCreateModal(false);
                    setSelectedRsvp(undefined)
                }}
                timeslot={selectedTimeSlot!}
                reservation={selectedRsvp}
                onUpdateTimeSlot={(ts) => {
                    setTimeSlots(timeSlots.map(timeslot => timeslot.id === ts.id ? ts : timeslot))
                    setSelectedTimeSlot(ts)
                }}
            />
            <CreateWaitListModal show={showAddToWaitListModal} onHide={() => {
                setShowAddToWaitListModal(false)
            }} timeslot={selectedTimeSlot!}/>
            <CreateNotificationModal show={showCreateNotificationModal} onHide={() => {
                setShowCreateNotificationModal(false)
            }} timeslot={selectedTimeSlot} notifications={[]} setNotifications={(p) => {
            }}/>
            <CreateRecurrentRsvpModal
                show={showCreateRecurrentRsvpModal}
                onHide={() => setShowCreateRecurrentRsvpModal(false)} timeslot={selectedTimeSlot!}
                onUpdateTimeSlot={(ts) => {
                    setTimeSlots(timeSlots.map(timeslot => timeslot.id === ts.id ? ts : timeslot))
                    setSelectedTimeSlot(ts)
                }}
            />
            <div className="mt-5 mb-8">
                <div className="d-flex align-items-center">
                    <i className="fi-rr-list-check mr-3"></i>
                    <h2 className="text-dark">Reservaciones</h2>
                </div>
            </div>

            <Row className="h-100 mt-5">
                {/* Starts column 1 -izquierda */}
                <Col sm={12} md={5} className="col-xl-4 col-xxl-3">
                    <div style={{backgroundColor: "white", height: "90%",}} className="rounded-lg shadow-sm p-5">
                        <div>
                            <div style={{opacity: "0.3"}} className="font-weight-bolder text-uppercase">
                                RESERVACIONES
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">

                            <div className="font-weight-bold font-size-h4">
                                {formatDateWithWeekday(selectedDate)}
                            </div>

                            <div className="ml-2" style={{cursor: "pointer", color: "#3699FF"}}>
                                <ReactDatePicker customInput={<FontAwesomeIcon icon={faCalendarDay}/>}
                                                 dateFormat="PPPP"
                                                 selected={selectedDate}
                                                 onChange={(d) => setSelectedDate(d as Date)}/>
                            </div>
                        </div>
                        <div className="form-group form-check">
                            <label className="clickable py-1 text-muted ">
                                <input className="form-check-input text-muted " type="checkbox"
                                       checked={includeArchivedSlots}
                                       onChange={() => setIncludeArchivedSlots(!includeArchivedSlots)}/>
                                <div className="ml-1 form-check-label">Incluir horarios eliminados</div>
                            </label>
                        </div>
                        {/* STATS */}
                        <div className="d-flex flex-row justify-content-around text-center mt-10 mb-10 d-none">
                            <div className="d-none d-sm-block">
                                <div>
                                    <p style={{opacity: "0.3", fontSize: "12px"}}
                                       className="m-0 text-uppercase  font-weight-bolder">
                                        HORARIOS
                                    </p>
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bold font-size-h6">{timeSlots.length}</p>
                                </div>
                            </div>
                            <div className="d-none d-sm-block">
                                <div>
                                    <p style={{opacity: "0.3", fontSize: "12px"}}
                                       className="m-0 text-uppercase  font-weight-bolder">
                                        TOTAL
                                    </p>
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bold font-size-h6">{timeSlots.reduce((acc, ts) => acc + ts.people_limit, 0)}</p>
                                </div>
                            </div>
                            <div className="d-none d-sm-block">
                                <div>
                                    <p style={{opacity: "0.3", fontSize: "12px"}}
                                       className="m-0 text-uppercase  font-weight-bolder">
                                        Ocupación
                                    </p>
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bold font-size-h6">{timeSlots.reduce((acc, ts) => acc + (ts.people_limit - ts.available_spaces), 0)}</p>
                                </div>
                            </div>
                        </div>
                        {/* END STATS */}

                        <div className="h-100">
                            {renderTimeSlots()}
                        </div>
                    </div>
                </Col>
                {/* Starts column 2 derecha*/}
                <Col sm={12} md={7} className="col-xl-8 col-xxl-9 ">
                    <div style={{backgroundColor: "white", height: "90%"}} className="rounded-lg shadow-sm p-5">
                        {renderSelectedTimeSlot()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Reservations;
