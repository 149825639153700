import React, {useEffect, useRef, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import { Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import TableFooter from "../../../components/table/TableFooter";
import {formatDate} from "../../../utils/dateFormatters";
import {MemberPlan} from "./MemberPlans";


interface Props {
    membership: MemberPlan,
}

const MembershipUpdateHistory = ({membership}: Props) => {
    const [items, setItems] = useState<MembershipUpdateItem[]>([]);
    const count = useRef(0)

    const handlePageChange = (page: number) => {
        baseApi.get<Paginated>(`memberships/updates/?membership=${membership.id}&page=1&page_size=10`).then(resp => {
            setItems(resp.data.results)
        }).catch()
    }

    useEffect(() => {
        baseApi.get<Paginated>(`memberships/updates/?membership=${membership.id}&page=1&page_size=10`).then(resp => {
            count.current = resp.data.count
            setItems(resp.data.results)
        }).catch()
    }, [membership]);

    const getUpdateTypeText = (update_type: number ) => {
        switch (update_type) {
            case 0:
                return "Modificado por staff"
            case 1:
                return "Primera reserva"
            case 2:
                return "Modificado por Stripe"
            case 3:
                return "Ajuste de congelamiento"
            default:
                return ""
        }
    }

    return (
        <div>
            <Table hover>
                <TableHeader headers={["Fecha", "Vencimiento Original", "Vencimiento Nuevo", "Tipo", "Creado por"]}/>
                <tbody>
                {items.length === 0 ? <EmptyTr/> : items.map((item) =>
                    <tr key={item.id} className="font-size-sm align-middle text-center">
                        <td>{formatDate(item.created_on)}</td>
                        <td>{formatDate(item.old_expiration)}</td>
                        <td>{formatDate(item.new_expiration)}</td>
                        <td>{getUpdateTypeText(item.update_type)}</td>
                        <td>{item.created_by_email}</td>
                    </tr>)}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={items.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MembershipUpdateHistory;

export interface MembershipUpdateItem {
    created_by: string;
    created_by_email: string;
    created_on: string;
    id: string;
    membership: string;
    new_expiration: string;
    old_expiration: string;
    update_type: number;
}